import { Box, Grid, Link, LinkProps, Typography, Container, Stack } from '@mui/material'
import { theme } from 'utils/theme'
import { useContext } from 'react'
import { TermsContext } from 'contexts/TermsContext'
import { staticPath } from 'utils/$path'

export const AppFooter = () => {
  const { privacy } = useContext(TermsContext)
  return (
    <Box
      sx={{
        width: '100%',
        borderTop: '1px solid',
        borderColor: 'divider',
        backgroundColor: '#f5f5f5',
        py: 3,
      }}
      component="footer"
    >
      <Container maxWidth="lg">
        <Box padding="20px">
          <img
            src={staticPath.portal.logo_png}
            alt="toridori marketing"
            style={{
              width: '200px',
            }}
          />
        </Box>
        <Grid container gap={2} p="40px">
          <Grid item xs={12} sm={4}>
            <FooterLink href="https://collabotechnology.zendesk.com/hc/ja" target="_blank" rel="noopener noreferrer">
              よくある質問
            </FooterLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FooterLink href={privacy} target="_blank" rel="noopener noreferrer">
              プライバシーポリシー
            </FooterLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FooterLink href="https://toridori.co.jp" target="_blank" rel="noopener noreferrer">
              会社概要
            </FooterLink>
          </Grid>
        </Grid>
        <Grid item xs={12} p={3}>
          <Stack direction="row" gap={0.3} sx={{ alignItems: 'center' }}>
            <a href="https://apps.apple.com/jp/app/id1448816493" target="blank">
              <img src={staticPath.apple_store_badge_svg} alt="Apple Store" style={{ height: '40px' }} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=jp.co.collabomarketing" target="blank">
              <img src={staticPath.google_play_badge_svg} alt="Google Play" style={{ height: '60px' }} />
            </a>
          </Stack>
        </Grid>
      </Container>
    </Box>
  )
}

const FooterLink = (props: LinkProps) => (
  <Link {...props} color={theme.palette.text.secondary} underline="hover" sx={{ cursor: 'pointer' }}>
    <Typography variant="body2">{props.children}</Typography>
  </Link>
)
