import { DateTime } from 'luxon'

export const stringHelper = {
  toFormatString(text?: string, format?: string) {
    if (text && format) return DateTime.fromISO(text).toFormat(format)
    if (text) return DateTime.fromISO(text).toFormat('yy/MM/dd')
    return DateTime.local().toFormat('yyyy年MM月dd日 HH:mm')
  },

  removeWhiteSpace: (str: string) => str.replace(/(\s|\u3000)+/g, ''),
} as const

export function snakeToCamel(snake: string): string {
  return snake.replace(/_./g, (s) => s.charAt(1).toUpperCase())
}

export function camelToSnake(camel: string): string {
  return camel.replace(/([A-Z])/g, (s) => '_' + s.charAt(0).toLowerCase())
}
