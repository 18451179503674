import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getAdvAccount } from 'repository/api/AdvAccountRepository'
import { getCurrentUserAdv } from 'repository/api/UserAdvRepository'
import { AdvAccount } from 'types/AdvAccount'
import { UserAdv } from 'types/UserAdv'
import Bugsnag from 'utils/bugsnag'
import { setUserId } from 'utils/gtmHelper'
import { snackActions } from 'utils/snackbarHelper'
import { auth, FirebaseUser } from 'utils/firebase/auth'
import { InflatingAppLoader } from 'components/common/InflatingAppLoader'
import { isMarketingApiError } from 'types/error/MarketingApiError'
import { pagesPath } from 'utils/$path'
import { proxyLogin } from 'utils/proxyLogin'
import { USER_FRONT_HOST } from 'utils/config'

export type IAuth = {
  // 初期値の場合(FirebaseUserが存在するかわからない時)はundefined, FirebaseUserの取得を試み、失敗した(存在しない)場合はnull
  currentUser: FirebaseUser | null | undefined
  currentUserAdv: UserAdv | null | undefined
  setCurrentUserAdv: (user: UserAdv) => void
  currentAdv: AdvAccount | null | undefined
  setCurrentAdv: (account: AdvAccount | null) => void
  /**
   * AdvAccountが参照している外部システムの状態が更新されて、状態の整合性を保ちたい場合などに
   * AdvAccountを再取得する（例：クレジットカードの登録）
   */
  refetchCurrentAdv: () => Promise<void>
}

const AuthContext = createContext<IAuth>(undefined as never)

export const AuthContextProvider = AuthContext.Provider

export const useAuth = () => {
  const { currentUser, currentAdv, currentUserAdv, ...rest } = useContext(
    AuthContext,
  )

  if (currentUser && currentUserAdv && currentAdv) {
    return { currentUser, currentAdv, currentUserAdv, ...rest }
  }

  throw new Error('Invalid useAuth call')
}

export const usePartialAuth = () => {
  const { currentUser, currentAdv, currentUserAdv, ...rest } = useContext(
    AuthContext,
  )

  if (
    currentUser !== undefined && currentUserAdv !== undefined &&
    currentAdv !== undefined
  ) {
    return { currentUser, currentAdv, currentUserAdv, ...rest }
  }

  throw new Error('Invalid usePartialAuth call')
}

export const useAuthController = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { currentUser, currentAdv, currentUserAdv, ...rest } = useContext(
    AuthContext,
  )

  return rest
}

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>()
  const [currentUserAdv, setCurrentUserAdv] = useState<UserAdv | null>()
  const [currentAdv, setCurrentAdv] = useState<AdvAccount | null>()
  const router = useRouter()
  const { enqueueSnackbar } = useSnackbar()

  // FIXME: ページ遷移（URLのパス変更）のたびにwindow.dataLayerに新しいconfigが追加され、
  //        setUserIdした値が書き換えられてします
  //        暫定対応：router.pathnameが変化するたびにsetしなおす
  //        そもそも何経由でこういう挙動になっているのかの調査は必要そう
  useEffect(() => {
    if (currentUserAdv?.id) {
      setUserId(currentUserAdv.id)
    }
  }, [router.pathname])

  const refetchCurrentAdv = async () => {
    try {
      const advAccount = await getAdvAccount()
      setCurrentAdv(advAccount)
    } catch (_) {
      snackActions.error('アカウント情報の更新に失敗しました')
    }
  }

  const excludePathes = [
    // signup, signinのパス配下ではチェックしない．このケースのみ認証状態に基づくredirect処理は各ページで行う
    pagesPath.reset_password.$url().pathname,
    pagesPath.signin.$url().pathname,
    pagesPath.signup.$url().pathname,
    // 認証用ページなのでリダイレクトしない
    pagesPath.webview_redirect.$url().pathname,
    pagesPath.auth_transfer.$url().pathname,
  ]

  const isAuthPage = useMemo(
    () => excludePathes.some((path) => router.pathname.startsWith(path)),
    [router.pathname],
  )

  const redirectToSignIn = () => {
    if (isAuthPage) return // 認証が不要なページであればリダイレクトしない
    router.pathname === '/'
      ? router.push(USER_FRONT_HOST)
      : router.push(pagesPath.signin.$url())
  }

  const handleAuthenticationError = (e: unknown) => {
    console.log(e)
    // TODO: エラーが記録されてなさそう
    if (!isMarketingApiError(e)) {
      enqueueSnackbar('エラーが発生しました', { variant: 'warning' })
      router.push(pagesPath.signin.$url())
      return
    }
    // FirebaseUserが存在すれば、userAdv, advAccountの作成画面へ遷移
    if (e.response.status === 401) return redirectToSetUpAccount()
    if (e.response.status === 400 && e.response.data.error.code === 'E1004') {
      console.log(e.response.data)
      redirectToSetUpAccount()
    }
  }

  const redirectToSetUpAccount = () => {
    enqueueSnackbar('サインアップ作業が途中です', { variant: 'warning' })
    if (router.pathname !== pagesPath.signup.account.$url().pathname) {
      router.push(pagesPath.signup.account.$url())
    }
  }

  // userAdvとadvAccountを取得してstateにセット
  const setupAccounts = async () => {
    let isSupport = false
    try {
      const userAdv = await getCurrentUserAdv() // userAdvの取得
      isSupport = userAdv.authority === 'support'
      setCurrentUserAdv(userAdv)
    } catch (e) {
      setCurrentUserAdv(null)
      setCurrentAdv(null) // userAdvの取得に失敗した場合は、advAccountも存在しないことが決まるため、advAccountの取得は行わずにnullにする
      handleAuthenticationError(e)
      return
    }
    // userAdvのが正常に取得できた場合のみ、advAccountの取得を開始する。
    // userAdvのみ存在し、advAccountがない場合は、サーバーやアプリ側で起こりうるため。
    // 逆にuserAdvはないが、advAccountが存在することはありえないため、userAdvの結果に応じてadvAccountの取得をする
    try {
      const advAccount = await getAdvAccount()
      if (isSupport) {
        // 代理ログインの場合は対象のAdvAccountのIDをセット
        proxyLogin.setProxyAdvAccountId(advAccount.id)
      }
      setCurrentAdv(advAccount)
    } catch (e) {
      setCurrentAdv(null)
      handleAuthenticationError(e)
    }
  }

  useEffect(() => {
    // FirebaseUserが存在していればそれをセットし、存在しなければ初期値のundefinedからnullに更新
    auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
      return setCurrentUser(user)
    })
  }, [])

  useEffect(() => {
    if (currentUser === undefined) return // FirebaseUser(currentUser)が初期値の場合(FirebaseUserの取得を試みていない場合)は何もしない

    if (currentUser === null) {
      // currentUserの取得を試みた結果、ユーザー存在しなかった場合(未ログイン or そもそもアカウントを作成していない場合)は、サインインページへリダイレクト
      setCurrentUserAdv(null)
      setCurrentAdv(null)
      redirectToSignIn()
    } else {
      // FirebaseUserが存在する場合は、userAdvとadvAccountを順番に取得し、stateにセットする
      setupAccounts()
    }
  }, [currentUser])

  // 全てのアカウント情報の取得が成功している場合かつ、本番環境のみBugsnagのセットアップをする
  useEffect(() => {
    if (!currentUser || !currentUserAdv || !currentAdv) return
    setUserId(currentUserAdv.id)
    Bugsnag.setUser(
      `${currentUserAdv.id}`,
      currentUserAdv.email,
      currentUserAdv.name,
    )
  }, [currentUser, currentUserAdv, currentAdv])

  const canRender = () => {
    // 全てのアカウント情報が揃っていたら表示可能
    if (currentUser && currentUserAdv && currentAdv) return true

    // 認証ページであれば、読み込みがすべて完了すれば表示可能
    if (
      isAuthPage && currentUser !== undefined && currentUserAdv !== undefined &&
      currentAdv !== undefined
    ) {
      // ただしログイン後にadvAccount, userAdvの取得に失敗したときは、/signup/account以外を表示しない
      // redirectToSetUpAccountと対応する
      if (
        currentUser !== null &&
        (currentUserAdv === null || currentAdv === null) &&
        router.pathname !== pagesPath.signup.account.$url().pathname
      ) {
        return false
      }

      return true
    }

    return false
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserAdv,
        setCurrentUserAdv,
        currentAdv,
        setCurrentAdv,
        refetchCurrentAdv,
      }}
    >
      {canRender() ? props.children : <InflatingAppLoader />}
    </AuthContext.Provider>
  )
}
