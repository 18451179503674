import { Alert, AlertTitle, Box, Link } from '@mui/material'
import { useEffect, useState } from 'react'
import { DYNAMIC_LINK } from 'utils/config'
import { getPlatform } from 'utils/platform'
import { theme } from 'utils/theme'

const MOBILE_WIDTH = 640
const STORAGE_KEY_HIDE = 'hideAppLinkBanner'
const BANNER_HEIGHT = '86px'

type Props = {
  bannerOpen: boolean
  onClose?: () => void
}

/**
 * AppLayout側で表示・非表示を知るため、stateとレンダリング結果を合わせて返すhooksを用意する
 */
export const useNativeAppBanner = () => {
  const [bannerOpen, setBannerOpen] = useState(false)

  const handleClose = () => {
    setBannerOpen(false)
    window.localStorage.setItem(STORAGE_KEY_HIDE, Date.now().toString())
  }

  useEffect(() => {
    if (getPlatform() === 'app') return

    const lastHide = window.localStorage.getItem(STORAGE_KEY_HIDE)
    // 半永久的的に非表示
    if (lastHide !== null) return

    if (window.screen.width < MOBILE_WIDTH) setBannerOpen(true)
  }, [])

  return {
    banner: <NativeAppBanner bannerOpen={bannerOpen} onClose={handleClose} />,
    bannerOpen: bannerOpen,
    bannerHeight: BANNER_HEIGHT,
  }
}

export const NativeAppBanner = ({ bannerOpen: show, onClose }: Props) => {
  return show ? (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        zIndex: 1000,
        height: BANNER_HEIGHT,
      }}
    >
      <Alert
        severity="error"
        icon={
          <Box
            sx={{
              width: 50,
              height: 50,
              borderRadius: '20%',
              overflow: 'hidden',
            }}
          >
            <img width={50} height={50} src="/appicon.png" alt="アイコン" />
          </Box>
        }
        sx={{
          whiteSpace: 'pre-wrap',
          backgroundColor: 'white',
          py: 2,
          [theme.breakpoints.down('sm')]: {
            py: 1.5,
          },
        }}
        onClose={onClose}
        closeText="閉じる"
      >
        <AlertTitle
          sx={{
            [theme.breakpoints.up('sm')]: {
              mt: 1,
            },
          }}
        >
          <Link
            sx={{
              fontSize: 'large',
              fontWeight: 'bold',
              color: 'info.main',
            }}
            href={DYNAMIC_LINK}
          >
            スマートフォンは非対応です。アプリ版を利用する。
          </Link>
        </AlertTitle>
      </Alert>
    </Box>
  ) : null
}
