/**
 * 虎の巻バナーを表示するかどうか。
 * 表示する場合は'true'。
 */
export const LOCAL_KEY_SHOW_TORANOMAKI_BANNER = 'showToranomaki'

/**
 * pf-apiのトークンをlocalStorageに保存するためのキー
 */
export const PF_TOKEN_LOCAL_STORAGE_KEY = 'pf-api-token'

/**
 * ポータルサインアップ・イン時の流入元をlocalStorageに保存するためのキー
 */
export const PORTAL_SIGN_SERVICE_ID = 'portal-signin-up-service-id'
