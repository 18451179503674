import { InstagramInsight, CompleteCountLevel } from 'generated/graphql'

// NOTE: 各種グラフの色の定義はアプリと共有している

/** 年齢別グラフ */
export const AGE_CHART_COLORS = ['#F44336', '#FD3F81', '#E261C2', '#A985EE', '#5AA0FC', '#00B2F0', '#00BCD4'] as const

/** 女性の年齢比グラフ */
export const FEMALE_CHART_COLORS = [
  '#F44336',
  '#FD3F81',
  '#E261C2',
  '#A985EE',
  '#5AA0FC',
  '#00B2F0',
  '#00BCD4',
] as const

/** 男性の年齢比グラフ */
export const MALE_CHART_COLORS = ['#2196F3', '#00B2FE', '#00C9ED', '#00DBC5', '#00E890', '#A3EE5B', '#FFEB3B'] as const

/**  性別比グラフ */
export const GENDER_CHART_COLORS = ['#03A9F4', '#FF75CC', '#FF9800'] as const

/** チャートの種類 */
export type ChartKind = keyof Pick<InstagramInsight, 'ageRate' | 'femaleAgeRate' | 'genderRate' | 'maleAgeRate'>

/** チャートの種類のキーに応じた色の定義 */
export const CHART_COLORS: Record<
  ChartKind,
  typeof AGE_CHART_COLORS | typeof FEMALE_CHART_COLORS | typeof MALE_CHART_COLORS | typeof GENDER_CHART_COLORS
> = {
  ageRate: AGE_CHART_COLORS,
  femaleAgeRate: FEMALE_CHART_COLORS,
  maleAgeRate: MALE_CHART_COLORS,
  genderRate: GENDER_CHART_COLORS,
} as const

export const COMPLETE_COUNT_LEVEL_COLORS: Record<
  CompleteCountLevel,
  { backgroundColor: string; borderColor: string; fontColor: string }
> = {
  normal: { backgroundColor: '#EBEBEB', borderColor: '#FAFAFA', fontColor: 'inherit' },
  bronze: { backgroundColor: '#C08D5E', borderColor: '#F4F0E9', fontColor: 'white' },
  silver: { backgroundColor: '#C9CACA', borderColor: '#D3D5D6', fontColor: 'white' },
  gold: { backgroundColor: '#EFAF00', borderColor: '#F5EBD6', fontColor: 'white' },
} as const

/**
 * toridori コーポレートカラー
 */
export const ToridoriColors = {
  yellow: {
    dark: '#b29200',
    main: '#ffd100',
    light: '#ffda33',
  },
  red: {
    dark: '#8f0012',
    main: '#cd001a',
    light: '#d73347',
  },
  blue: {
    dark: '#003c80',
    main: '#0057b7',
    light: '#3378c5',
  },
  green: {
    dark: '#014f18',
    main: '#027223',
    light: '#348e4f',
  },
} as const
