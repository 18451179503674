import Logout from '@mui/icons-material/Logout'
import CreditCard from '@mui/icons-material/CreditCard'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { useRouter } from 'next/router'
import { MutableRefObject, useRef, useState } from 'react'
import { AdvAccount } from 'types/AdvAccount'
import { UserAdv } from 'types/UserAdv'
import { theme } from 'utils/theme'
import { pagesPath } from 'utils/$path'
import { auth } from '~/utils/firebase/auth'

export const UserMenu: React.FC = () => {
  const { currentUserAdv, currentAdv } = useAuth()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const ref = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const anchorEl = open ? ref.current : null

  const router = useRouter()
  const handleSignout = () => {
    auth.signOut().then(() => {
      router.push(pagesPath.signin.$url())
    })
  }

  const handlePaymentSetting = () => {
    router.push(pagesPath.adv_account.register_payment.$url())
    handleClose()
  }

  return (
    <UserMenuPresenter
      anchorRef={ref}
      anchorEl={anchorEl}
      userAdv={currentUserAdv}
      advAccount={currentAdv}
      avatarOnly={isMobile}
      onSignout={handleSignout}
      onPaymentSetting={handlePaymentSetting}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  )
}

type PresenterProps = {
  anchorRef: MutableRefObject<HTMLDivElement | null>
  anchorEl: HTMLElement | null
  userAdv: UserAdv
  advAccount: AdvAccount
  avatarOnly: boolean
  onSignout?: () => void
  onPaymentSetting?: () => void
  onOpen?: () => void
  onClose?: () => void
}

export const UserMenuPresenter: React.FC<PresenterProps> = ({
  anchorRef,
  anchorEl,
  userAdv,
  advAccount,
  avatarOnly,
  onSignout,
  onPaymentSetting,
  onOpen,
  onClose,
}) => (
  <div ref={anchorRef}>
    {avatarOnly
      ? (
        <IconButton
          onClick={onOpen}
          sx={{
            width: 26,
            height: 26,
          }}
        >
          <Avatar
            sx={{
              width: 26,
              height: 26,
            }}
          />
        </IconButton>
      )
      : (
        <Chip
          avatar={<Avatar />}
          label={`${userAdv.name} 様`}
          onClick={onOpen}
          sx={{
            fontWeight: 'bold',
          }}
          variant='outlined'
        />
      )}

    <Popover
      anchorEl={anchorEl}
      disablePortal
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Card sx={{ minWidth: 300, maxWidth: '100%' }}>
        {avatarOnly && (
          <CardContent>
            <Stack gap={1}>
              <Typography variant='caption' color='primary'>
                お客様ID {userAdv.id}
              </Typography>
              <Box>
                <Typography display='flex' flexDirection='row' flexWrap='wrap'>
                  {userAdv.name} 様
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    component='span'
                  >
                    （{advAccount.companyName}）
                  </Typography>
                </Typography>
              </Box>
              <Typography variant='body2' color='text.secondary'>
                {userAdv.email}
              </Typography>
            </Stack>
          </CardContent>
        )}

        <Divider />
        <MenuList sx={{ margin: 0 }}>
          {
            // 支払い方法が自社請求書以外（MFKESSAI or クレジット)の人だけ出す
            advAccount.paymentType !== 'invoice' && (
              <MenuItem onClick={onPaymentSetting}>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  fontSize='small'
                >
                  <CreditCard fontSize='inherit' sx={{ mr: 1 }} />
                  お支払いに関する設定
                </Typography>
              </MenuItem>
            )
          }
          <MenuItem onClick={onSignout}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              color='error'
              fontSize='small'
            >
              <Logout fontSize='inherit' sx={{ mr: 1 }} />
              ログアウト
            </Typography>
          </MenuItem>
        </MenuList>
      </Card>
    </Popover>
  </div>
)
