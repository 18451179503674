import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { APP_ENV, BUGSNAG_API_KEY } from './config'

/**
 * 除外したいエラーメッセージの一覧
 * （エラークラスによる除外はbugsnagのWebコンソール上で行う）
 */
const WHITE_MESSAGE_LIST = [
  'ResizeObserver loop limit exceeded',
  'NetworkError',
  'Failed to fetch',
]

Bugsnag.start({
  releaseStage: APP_ENV,
  enabledReleaseStages: ['production', 'staging'],
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (event.originalError instanceof Error) {
      if (
        WHITE_MESSAGE_LIST.some((m) => event.originalError.message.includes(m))
      ) {
        return false
      }
    }

    event.addMetadata('SourceVersion', {
      buildDateTime: process.env.NEXT_PUBLIC_BUILD_DATE_TIME,
      buildRevision: process.env.NEXT_PUBLIC_BUILD_REVISION,
    })
  },
  ...(process.env.NODE_ENV === 'development' && {
    // 頻繁に出力される警告を抑制する
    logger: {
      debug: console.debug,
      info: console.debug,
      warn: console.debug,
      error: console.error,
    },
  }),
})

export default Bugsnag
