import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { TAG_MANAGER_ID } from 'utils/config'

export const useTagManagerInit = () => {
  useEffect(() => {
    const id = TAG_MANAGER_ID

    TagManager.initialize({ gtmId: id })

    console.debug(`gtm initialized with ${id}`)
  })
}
