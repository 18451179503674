import MenuIcon from '@mui/icons-material/Menu'
import { Drawer as MuiDrawer, IconButton, styled, Toolbar } from '@mui/material'
import { staticPath } from 'utils/$path'
import { drawerWidth } from 'utils/theme'
import { AppDrawerContent } from './AppDrawerContent'

type Props = {
  open: boolean
  onClose?: () => void
  isMobile: boolean
}

export const AppDrawer = ({ open, onClose, isMobile }: Props) => (
  <>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        display: { xs: 'block', md: 'none' },
      }}
      variant="temporary"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      anchor="left"
      open={isMobile ? open : false}
    >
      <Toolbar sx={{ gap: 1, justifyContent: 'center' }}>
        <img
          src={staticPath.toridori_marketing_logo_1_png}
          alt="トリドリマーケティング"
          style={{ height: '24px', objectFit: 'contain' }} />
        <IconButton onClick={onClose}>
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <AppDrawerContent />
    </Drawer>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        display: { xs: 'none', md: 'block' },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#f5f5f5',
          border: 'none',
        }
      }}
      variant="permanent"
      anchor="left"
      open
    >
      <Toolbar sx={{ gap: 1, justifyContent: 'center' }}>
        <img
          src={staticPath.toridori_marketing_logo_1_png}
          alt="トリドリマーケティング"
          style={{ height: '24px', objectFit: 'contain' }} />
        <IconButton onClick={onClose}>
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <AppDrawerContent />
    </Drawer>
  </>
)

const Drawer = styled(MuiDrawer)(({ theme, open, variant }) => ({
  // persistentなDrawerは、open=falseのときに余白が残るので、marginで相殺する
  ...(variant === 'persistent' && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
}))
