import MenuIcon from '@mui/icons-material/Menu'
import { Toolbar, Box, IconButton, Link, AppBar } from '@mui/material'
import NextLink from 'next/link'
import { pagesPath, staticPath } from 'utils/$path'
import { UserInfo } from './UserInfo'
import { UserMenu } from './UserMenu'

type Props = {
  isMobile: boolean
  onDrawerOpen?: () => void
  bannerHeight?: string
  bannerOpen?: boolean
}

export const AppHeader = ({ isMobile, onDrawerOpen, bannerHeight, bannerOpen = false }: Props) => {
  return (
    <AppBar
      sx={{
        position: 'fixed',
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
        top: bannerOpen ? bannerHeight : 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar component="header">
        <>
          { isMobile && (
            <IconButton color="inherit" onClick={onDrawerOpen} edge="start" size="large">
              <MenuIcon />
            </IconButton>
          )}
          <NextLink href={pagesPath.portal.$url()} passHref legacyBehavior>
            <Link
              sx={{
                lineHeight: 1,
                height: '24px',
                ml: 1,
              }}
            >
              <img
                src={staticPath.portal.logo_png}
                alt="トリドリマーケティング"
                style={{ height: '100%', objectFit: 'contain' }}
              />
            </Link>
          </NextLink>
        </>

        <Box sx={{ flexGrow: 1 }}></Box>

        {/* お客様情報 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UserInfo />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
