import { APP_ID } from "./config"

export const PlatformKind = {
  App: 'app',
  Web: 'web',
  Unknown: 'unknown',
} as const

export type PlatformKind = typeof PlatformKind[keyof typeof PlatformKind]

export const getPlatform = () => {
  const ua = navigator.userAgent
  if (typeof ua !== 'string') {
    return PlatformKind.Unknown
  } else if (ua.includes(APP_ID)) {
    return PlatformKind.App
  } else {
    return PlatformKind.Web
  }
}
