import { SnackbarProvider } from 'notistack'
import { ReactNode } from 'react'
import { snackActions, SnackbarUtilsConfigurator } from 'utils/snackbarHelper'

export const AppSnackbarProvider = ({ children }: { children: ReactNode }) => (
  <SnackbarProvider
    maxSnack={5}
    SnackbarProps={{
      onClick: () => snackActions.dismiss,
    }}
    autoHideDuration={3000}
    preventDuplicate
  >
    <SnackbarUtilsConfigurator />
    {children}
  </SnackbarProvider>
)
