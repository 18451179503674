import { ApiRequest } from './client'

type TermsResponse = {
  privacyPolicyUrl: string
  termUrl: string
}

export const getTerms = async () => {
  return await ApiRequest<TermsResponse>({
    url: `/v1/terms`,
  })
}

type ShowTermConsentResponse = {
  needConsentLatestTerm: boolean
  privacyPolicyUrl: string
  termUrl: string
}

export const checkTermConsent = async () => {
  return await ApiRequest<ShowTermConsentResponse>({
    url: `/v1/terms/consent`,
  })
}

export const postTermConsent = async () => {
  return await ApiRequest<void>({
    method: 'POST',
    url: `/v1/terms/consent`,
  })
}
