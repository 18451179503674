import { useState } from 'react'
import { drawerWidth, theme } from 'utils/theme'
import { AppFooter } from './AppFooter'
import { useNativeAppBanner } from 'components/common/NativeAppBanner'
import { AppHeader } from './AppHeader'
import { AppDrawer } from './AppDrawer'
import { Box, Stack, Toolbar, useMediaQuery } from '@mui/material'

export const AppLayout = (props: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { banner, bannerOpen: show, bannerHeight } = useNativeAppBanner()

  return (
    <>
      {banner}

      <Stack
        sx={{
          mt: show ? bannerHeight : 0,
        }}
      >
        <AppDrawer open={drawerOpen} isMobile={isMobile} onClose={() => setDrawerOpen(false)} />

        <Box
          sx={(theme) => ({
            ml: !isMobile ? drawerWidth + 'px' : 0,
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.leavingScreen,
            }),
          })}
        >
          <AppHeader
            isMobile={isMobile}
            onDrawerOpen={() => setDrawerOpen(!drawerOpen)}
            bannerHeight={bannerHeight}
            bannerOpen={show}
          />
          <Box
            sx={{
              minHeight: '100vh',
            }}
            component="main"
          >
            {/* AppBarの高さ分 */}
            <Toolbar />

            {props.children}
          </Box>
          {/* NOTE: iOS WebviewでFooterが崩れる */}
          {/* 暫定: isMobile時に表示しない */}
          {!isMobile && <AppFooter />}
        </Box>
      </Stack>
    </>
  )
}
