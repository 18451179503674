/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloClient, ApolloProvider, InMemoryCache, TypePolicies, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { PF_TOKEN_LOCAL_STORAGE_KEY } from 'constants/local_storage_key'
import { ReactNode, FC } from 'react'
import { getPfToken } from 'repository/api/PfApiRepository'
import dayjs from 'dayjs'
import { proxyLogin } from 'utils/proxyLogin'
import { relayStylePagination } from '@apollo/client/utilities'
import { GRAPHQL_API_HOST } from 'utils/config'

const uri = GRAPHQL_API_HOST + '/graphql'
const uploadLink = createUploadLink({ uri })

/**
 * トークンの有効期限が切れているかのフラグ
 * onErrorに非同期コールバックを渡せないため、やむを得ずこうしてる
 * */
let isExpired = false
const authLink = setContext(async (_, { headers }) => {
  const pfToken = await getPfToken({ refresh: isExpired })
  isExpired = false
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${pfToken}`,
    },
  }
})

const errorLink = onError(({ graphQLErrors, operation, response, forward }) => {
  if (!graphQLErrors) return
  graphQLErrors.forEach(({ message, extensions }, i) => {
    if (!('code' in extensions)) return
    const { code } = extensions
    switch (code) {
      case 'UNAUTHENTICATED':
        // トークン期限切れエラーの場合は期限切れフラグを有効にし、リトライする
        // onErrorに非同期コールバックを渡せればここでgetPfTokenをawaitすればよいが、出来ないためフラグを有効にし、
        // authLinkに期限切れであることを伝える
        isExpired = message.includes('TokenExpiredError')
        break
      case 'FORBIDDEN':
        if (response?.errors?.length) response.errors[i]!.message = 'アクセス権限がありません'
        break

      case 'UNKNOWN_ERROR':
        if (response?.errors?.length) response.errors[i]!.message = '不明なエラーです'
        break
      case 'INTERNAL_SERVER_ERROR':
        if (response?.errors?.length) response.errors[i]!.message = 'サーバーエラーが発生しました'
        break
      case 'GRAPHQL_VALIDATION_FAILED':
      case 'BAD_USER_INPUT':
        break // これらはサーバー側でエラー文言が付与されているため何もしない
      default:
        break
    }
  })

  return isExpired ? forward(operation) : undefined
})

// TODO: fetchMore内でのupdateQueryがdeprecatedでApollo Client v4.0で削除されるらしいので、フィールドポリシー内でキャッシュを操作する @mayone-du
export const typePolicies: TypePolicies = {
  Company: {
    fields: {
      issuedTickets: relayStylePagination(),
    },
  },
  PrInsight: {
    fields: {
      aggregationTargetDate: {
        read: (val: string) => {
          return dayjs(val).format('MM/DD')
        },
      },
    },
  },
}

const client = new ApolloClient({
  uri,
  link: from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies,
  }),
})

export const removePfToken = () => {
  localStorage.removeItem(PF_TOKEN_LOCAL_STORAGE_KEY)
  client.clearStore()
  proxyLogin.clearProxyAdvAccountId()
}

export const AppApolloProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
