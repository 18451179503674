import { Box, Typography, useMediaQuery } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { theme } from 'utils/theme'

export const UserInfo: React.FC = () => {
  const { currentUserAdv, currentAdv } = useAuth()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const hiddenAdvId = <input type="hidden" id="smash-adv-id" value={currentAdv.id || undefined } />;
  const hiddenRoute = <input type="hidden" id="smash-adv-route" value={currentAdv.route || undefined } />;
  const hiddenReferralId = <input type="hidden" id="smash-referral-id" value={currentAdv.referralAgencyId || undefined} />;
  const hiddenContractType = <input type="hidden" id="smash-referral-contract-type" value={currentAdv.referralAgencyContractType || undefined} />;
  if (isMobile) return <>{hiddenAdvId}{hiddenRoute}{hiddenReferralId}{hiddenContractType}</>
  return (
    <Box display="flex" alignItems="center" sx={{ gap: '20px', mr: 1 }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="caption" fontWeight="bold" noWrap>
          {currentAdv.companyName}
        </Typography>
        <Typography variant="caption" fontWeight="bold" noWrap>
          {hiddenAdvId}
          {hiddenRoute}
          {hiddenReferralId}
          {hiddenContractType}
          お客様ID: {currentUserAdv.id}
        </Typography>
      </Box>
    </Box>
  )
}
