import { useEffect, useState } from 'react'
import { LinearProgress } from '@mui/material'
import { useRouter } from 'next/router'

export const TopProgress = () => {
  const [percent, setPercent] = useState(0)
  const [show, setShow] = useState(false)
  const router = useRouter()

  // 1.5秒ごとにノリですすめるやつ
  useEffect(() => {
    if (show) {
      const interval = setInterval(() => {
        if (percent === 100) return

        if (Math.random() > 0.5) {
          setPercent((p) => p + 10)
        }
      }, 1500)

      return () => clearInterval(interval)
    } else {
      setPercent(0)
    }
  }, [show])

  useEffect(() => {
    // routeがかわるごとの処理
    const handleRouteChangeStart = () => {
      setShow(true)
      setPercent(0)
    }

    const handleRouteChangeComplete = () => {
      setPercent(100)
      setTimeout(() => {
        setShow(false)
      }, 500)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeComplete)
    }
  }, [router])

  return (
    <LinearProgress
      variant="determinate"
      value={percent}
      color="info"
      sx={{
        position: 'fixed',
        zIndex: 10000,
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        opacity: show ? 1 : 0,
        transition: 'opacity 0.1s ease',
      }}
    />
  )
}
