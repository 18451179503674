import { NoSsr } from '@mui/material'
import { AppLayout } from 'components/layout/AppLayout'
import { AuthProvider } from 'contexts/AuthContext'
import { TermsProvider } from 'contexts/TermsContext'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ReactNode } from 'react'
import { AppApolloProvider } from 'components/provider/AppApolloProvider'
import { useReloadAfterOneDay } from 'hooks/app/useReloadAfterOneDay'
import { useTagManagerInit } from 'hooks/app/useTagManagerInit'
import { BugsnagErrorBoundary } from 'components/error_boundary/BugsnagErrorBoundary'
import { AppSnackbarProvider } from 'components/provider/AppSnackbarProvider'
import { AppThemeProvider } from 'components/provider/AppThemeProvider'
import { FacebookSDK } from 'components/common/Facebook/FacebookSDK'
import { TopProgress } from 'components/TopProgress'
import { CustomErrorPage } from 'components/common/CustomErrorPage'
import { UNDER_MAINTENANCE } from 'constants/maintenance'

// 開発環境の場合はmswを読み込む
if (process.env.NODE_ENV === 'development') require('../mocks/msw')
// Provider類を全てネストさせたコンポーネント
const providers = [
  NoSsr,
  BugsnagErrorBoundary,
  AppApolloProvider,
  TermsProvider,
  AppSnackbarProvider,
  AuthProvider,
  AppThemeProvider,
]
const CompositeProvider = ({ children }: { children: ReactNode }) => (
  <>
    {providers.reduceRight(
      (children, Provider) => <Provider>{children}</Provider>,
      children,
    )}
  </>
)

export default function App({ Component, pageProps }: AppProps) {
  useReloadAfterOneDay()
  useTagManagerInit()
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (UNDER_MAINTENANCE) {
    return (
      <>
        <Head>
          <title>システムメンテナンス中 | toridori marketing</title>
        </Head>
        <CustomErrorPage
          errorTitle='現在システムメンテナンス中のため、サービスをご利用いただけません。'
          errorDetail={'システムメンテナンスが終了次第、再度ご利用ください。'}
          showTopLink={false}
        />
      </>
    )
  }

  /**
   * NOTE: Pagesコンポーネントにて、LayoutプロパティにAppLayout以外のレイアウトを指定することで、
   *        レイアウトを変更することができる。
   *        現状、AppLayoutを外したいユースケースにて、LayoutWithNothingコンポーネントを指定することで
   *        サイドメニューやヘッダの必要ないログイン画面等を標示している。
   *        なお、明示的にLayoutプロパティを指定しなかった場合は、AppLayoutが適用される。
   */
  const Layout = (Component as { Layout?: React.ComponentType }).Layout ||
    AppLayout

  return (
    <>
      <Head>
        <title>toridori marketing portal</title>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
      </Head>
      <FacebookSDK />
      <CompositeProvider>
        <TopProgress />
        <Layout>
          <Component {...pageProps} />
        </Layout>
        <TopProgress />
      </CompositeProvider>
    </>
  )
}
