/**
 * 代理ログイン中のAdvAccountのIDを管理する
 */
class ProxyLogin {
  private proxyAdvAccountId: number | null

  constructor() {
    this.proxyAdvAccountId = null
  }
  setProxyAdvAccountId(id: number) {
    this.proxyAdvAccountId = id
  }
  /**
   * 呼び出し前に必ずisProxyLogin()でチェックしてください
   */
  getProxyAdvAccountId(): number {
    if (!this.isProxyLogin()) {
      throw new Error('not proxy login')
    }
    return this.proxyAdvAccountId as number
  }
  clearProxyAdvAccountId() {
    this.proxyAdvAccountId = null
  }
  isProxyLogin(): boolean {
    return this.proxyAdvAccountId !== null
  }
}

export const proxyLogin = new ProxyLogin()
