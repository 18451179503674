import { Alert, Box, Container, Stack, Typography } from '@mui/material'
import { MarketingButton } from 'components/common/MarketingButton'
import { useRouter } from 'next/router'
import { staticPath } from 'utils/$path'
import { FC } from 'react'

type Props = {
  errorTitle: string
  errorDetail: string | string[]
  showTopLink?: boolean
  type?: number | string
  forDev?: {
    error: Error
    info: React.ErrorInfo
  }
}

export const CustomErrorPage: FC<Props> = ({ errorTitle, errorDetail, showTopLink = true, type, forDev }) => {
  const router = useRouter()

  const switchRouter = () => {
    if (!type) {
      asyncRouter()
    } else {
      router.push(window.location.origin)
    }
  }

  const asyncRouter = async () => {
    await router.push(window.location.origin)
    router.reload()
  }

  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center" bgcolor="#fff" borderRadius="8px" p={4}>
        <img
          src={staticPath.static.error_shutter_background_png}
          style={{ width: 212, height: 285 }}
          alt="エラーが発生しました。"
        />
        <Box height={32} />
        <Typography variant="h6" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {errorTitle}
        </Typography>
        <Box height={32} />

        {Array.isArray(errorDetail) ? (
          errorDetail.map((e, index) => <Typography key={index}>{e}</Typography>)
        ) : (
          <Typography>{errorDetail}</Typography>
        )}
        <Box height={32} />
        {forDev && (
          <Alert severity="error">
            <Stack gap={2}>
              <Typography component="pre" variant="body2" color="error">
                {forDev.error.message}
              </Typography>
              <Typography component="pre" variant="body2" color="error">
                {forDev.error.stack}
              </Typography>
              Component Stack
              <Typography component="pre" variant="body2" color="error">
                {forDev.info.componentStack}
              </Typography>
            </Stack>
          </Alert>
        )}
        <Box height={32} />
        {showTopLink && (
          <MarketingButton kind="primary" size="large" style={{ textTransform: 'none' }} onClick={() => switchRouter()}>
            ホームに戻る
          </MarketingButton>
        )}
      </Box>
    </Container>
  )
}
