import HomeIcon from '@mui/icons-material/Home'
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import InstagramIcon from '@mui/icons-material/Instagram'
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { pagesPath } from 'utils/$path'
import { ReactNode } from 'react'
import dayjs, { Dayjs } from 'dayjs'

type MenuRoute = {
  path: string
  text: string
  Icon: ReactNode
  showNewIconUntil?: Dayjs
}

const menuRoutes: readonly MenuRoute[] = [
  {
    path: pagesPath.portal.$url().pathname,
    text: 'サービス',
    Icon: <HomeIcon />,
  },
  {
    path: pagesPath.payment.$url().pathname,
    text: '請求一覧',
    Icon: <CurrencyYenIcon />,
  },
  {
    path: pagesPath.adv_account.instagram_info.$url().pathname,
    text: 'インスタグラム連携情報',
    Icon: <InstagramIcon />,
  },
] as const

export const AppDrawerContent = () => {
  const { asPath } = useRouter()
  return (
    <Stack height='100%'>
      <MenuList sx={{ pt: 3, px: 2 }}>
        {menuRoutes.map(({ path, text, Icon, showNewIconUntil }, i) => (
          <NextLink href={path} passHref key={path} legacyBehavior>
            <MenuItem
              // 指定しないとなぜかtab keyによるfocusが当たらない
              tabIndex={i === 0 ? 0 : undefined}
              key={path}
              focusRipple
              component='a'
              selected={asPath.startsWith(path)}
              sx={{ height: 50, position: 'relative', borderRadius: 2 }}
            >
              <ListItemIcon sx={{ color: 'primary.main' }}>{Icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 'small',
                  fontWeight: 'bold',
                }}
              >
                {text}
              </ListItemText>
              {showNewIconUntil && dayjs().isBefore(showNewIconUntil) && (
                <FiberNewIcon
                  sx={{
                    position: 'absolute',
                    right: 10,
                    color: 'error.main',
                    fontSize: 30,
                  }}
                />
              )}
            </MenuItem>
          </NextLink>
        ))}
      </MenuList>
    </Stack>
  )
}
