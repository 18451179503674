const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "adv_account": {
    "credit": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/credit' as const, hash: url?.hash })
    },
    "credit_register": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/credit_register' as const, hash: url?.hash })
    },
    "credit_registration": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/credit_registration' as const, hash: url?.hash })
    },
    "instagram_info": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/instagram_info' as const, hash: url?.hash })
    },
    "mfkessai_customer_info": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/mfkessai_customer_info' as const, hash: url?.hash })
    },
    "mfkessai_registration": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/mfkessai_registration' as const, hash: url?.hash })
    },
    "register_payment": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/register_payment' as const, hash: url?.hash })
    }
  },
  "auth_transfer": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth_transfer' as const, hash: url?.hash })
  },
  "creditcards": {
    "payment_histories": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/creditcards/payment_histories' as const, hash: url?.hash })
    }
  },
  "payment": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/payment' as const, hash: url?.hash })
  },
  "portal": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/portal' as const, hash: url?.hash })
  },
  "register_complete": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/register_complete' as const, hash: url?.hash })
  },
  "reset_password": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reset_password' as const, hash: url?.hash })
  },
  "sign_in_redirect": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/sign_in_redirect' as const, hash: url?.hash })
  },
  "signin": {
    "support": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin/support' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin' as const, hash: url?.hash })
  },
  "signup": {
    "account": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/account' as const, hash: url?.hash })
    },
    "password": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/password' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup' as const, hash: url?.hash })
  },
  "webview_redirect": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/webview_redirect' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  animation: {
    congrats_lottie_json: '/animation/congrats.lottie.json'
  },
  appicon_png: '/appicon.png',
  apple_store_badge_svg: '/apple-store-badge.svg',
  campaign_placeholder: {
    placeholder_1_jpg: '/campaign_placeholder/placeholder_1.jpg',
    placeholder_2_jpg: '/campaign_placeholder/placeholder_2.jpg',
    placeholder_3_jpg: '/campaign_placeholder/placeholder_3.jpg',
    placeholder_4_jpg: '/campaign_placeholder/placeholder_4.jpg',
    placeholder_5_jpg: '/campaign_placeholder/placeholder_5.jpg',
    placeholder_6_jpg: '/campaign_placeholder/placeholder_6.jpg'
  },
  facebook_connect_steps: {
    facebook_auth: {
      step_0_webp: '/facebook_connect_steps/facebook_auth/step-0.webp',
      step_1_webp: '/facebook_connect_steps/facebook_auth/step-1.webp',
      step_2_webp: '/facebook_connect_steps/facebook_auth/step-2.webp',
      step_3_webp: '/facebook_connect_steps/facebook_auth/step-3.webp'
    },
    facebook_page: {
      step_0_webp: '/facebook_connect_steps/facebook_page/step-0.webp',
      step_1_webp: '/facebook_connect_steps/facebook_page/step-1.webp',
      step_2_webp: '/facebook_connect_steps/facebook_page/step-2.webp',
      step_3_webp: '/facebook_connect_steps/facebook_page/step-3.webp',
      step_4_webp: '/facebook_connect_steps/facebook_page/step-4.webp',
      step_5_webp: '/facebook_connect_steps/facebook_page/step-5.webp',
      step_6_webp: '/facebook_connect_steps/facebook_page/step-6.webp',
      step_7_webp: '/facebook_connect_steps/facebook_page/step-7.webp'
    },
    instagram_pro_account: {
      intro_webp: '/facebook_connect_steps/instagram_pro_account/intro.webp',
      step_0_webp: '/facebook_connect_steps/instagram_pro_account/step-0.webp',
      step_1_webp: '/facebook_connect_steps/instagram_pro_account/step-1.webp',
      step_2_webp: '/facebook_connect_steps/instagram_pro_account/step-2.webp',
      step_3_webp: '/facebook_connect_steps/instagram_pro_account/step-3.webp',
      step_4_webp: '/facebook_connect_steps/instagram_pro_account/step-4.webp',
      step_5_webp: '/facebook_connect_steps/instagram_pro_account/step-5.webp',
      step_6_webp: '/facebook_connect_steps/instagram_pro_account/step-6.webp',
      step_7_webp: '/facebook_connect_steps/instagram_pro_account/step-7.webp'
    },
    intro_png: '/facebook_connect_steps/intro.png',
    reconnect_webp: '/facebook_connect_steps/reconnect.webp'
  },
  favicon_ico: '/favicon.ico',
  google_play_badge_svg: '/google-play-badge.svg',
  line_png: '/line.png',
  logo_icon_png: '/logo_icon.png',
  mockServiceWorker_js: '/mockServiceWorker.js',
  no_image_png: '/no_image.png',
  portal: {
    example_card_media_png: '/portal/example_card_media.png',
    example_marketing_logo_png: '/portal/example_marketing_logo.png',
    logo_png: '/portal/logo.png'
  },
  static: {
    error_shutter_background_png: '/static/error_shutter_background.png'
  },
  toridori_marketing_logo_1_png: '/toridori_marketing_logo_1.png'
} as const;

export type StaticPath = typeof staticPath;
