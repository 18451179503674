import { FACEBOOK_API_VERSION } from 'constants/facebook'
import Script from 'next/script'
import { FC, useCallback } from 'react'
import { FACEBOOK_APP_ID } from 'utils/config'

export const FacebookSDK: FC = () => {
  const handleLoadFacbookSDK = useCallback(() => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: FACEBOOK_API_VERSION,
      })
    }
  }, [])

  return (
    <Script
      strategy='lazyOnload'
      crossOrigin='anonymous'
      src='https://connect.facebook.net/ja_JP/sdk.js'
      onLoad={handleLoadFacbookSDK}
    />
  )
}
