// eslint-disable-next-line no-restricted-imports -- firebase/authをimportするため
import {
  Auth,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth'
import { firebaseApp } from './app'
import { eventPush, setUserId } from 'utils/gtmHelper'
import { removePfToken } from 'components/provider/AppApolloProvider'

// eslint-disable-next-line no-restricted-imports -- firebase/authをimportするため
export type { User as FirebaseUser } from 'firebase/auth'

const firebaseAuth = getAuth(firebaseApp)

const fixed = <Args extends unknown[], Result>(
  fn: (auth: Auth, ...args: Args) => Result,
) => {
  return (...args: Args) => fn(firebaseAuth, ...args)
}

export const auth = {
  onAuthStateChanged: firebaseAuth.onAuthStateChanged.bind(firebaseAuth),

  signInWithCustomToken: fixed(signInWithCustomToken),
  signInWithEmailAndPassword: fixed(signInWithEmailAndPassword),
  signUpWithEmailAndPassword: fixed(createUserWithEmailAndPassword),
  fetchSignInMethodsForEmail: fixed(fetchSignInMethodsForEmail),
  sendPasswordResetEmail: fixed(sendPasswordResetEmail),

  getCurrentIdToken: () => firebaseAuth.currentUser?.getIdToken(),

  signOut: async () => {
    await firebaseAuth.signOut()
    removePfToken()
    setUserId(null)
    eventPush('sign_out', {})
  },

  signInWithGoogle: async () => {
    const provider = new GoogleAuthProvider()
    const cred = await signInWithPopup(firebaseAuth, provider)
    sendNewUserEvent(cred, 'google')
    eventPush('login', { method: 'google' })
  },

  signInWithApple: async () => {
    const provider = new OAuthProvider('apple.com')
    provider.setCustomParameters({ locale: 'ja' })
    const cred = await signInWithPopup(firebaseAuth, provider)
    sendNewUserEvent(cred, 'apple')
    eventPush('login', { method: 'apple' })
  },
}

const sendNewUserEvent = (
  cred: UserCredential,
  provider: 'apple' | 'google',
) => {
  const additionalUserInfo = getAdditionalUserInfo(cred)
  if (additionalUserInfo?.isNewUser) {
    eventPush('create_firebase_user', {
      method: provider,
    })
  }
}
