import { camelToSnake, snakeToCamel } from './stringHelper'

export function camelizeKeys(input: any) {
  const isObject = Object.prototype.toString.call(input) === '[object Object]'
  const isArray = Object.prototype.toString.call(input) === '[object Array]'
  if (isObject) {
    const newObject: { [key: string]: any } = {}
    for (const key in input) {
      const newKey = snakeToCamel(key)
      newObject[newKey] = camelizeKeys(input[key])
    }
    return newObject
  } else if (isArray) {
    return input.map((element: any) => camelizeKeys(element))
  }
  return input
}

export function snakizeKeys(input: any) {
  const isObject = Object.prototype.toString.call(input) === '[object Object]'
  const isArray = Object.prototype.toString.call(input) === '[object Array]'
  if (isObject) {
    const newObject: { [key: string]: any } = {}
    for (const key in input) {
      const newKey = camelToSnake(key)
      newObject[newKey] = snakizeKeys(input[key])
    }
    return newObject
  } else if (isArray) {
    return input.map((element: any) => snakizeKeys(element))
  }
  return input
}
